import request from "../utils/request";



// 获取城市数据
export function getCities(data) {
    return request({
        url: '/api/cities/lists',
        method: 'get',
        params:data
    })
}


export function login(data) {
    return request({
        url: '/api/public/login',
        method: 'post',
        data
    })
}


export function getStaff(data) {
    return request({
        url: '/api/energy/staff',
        method: 'get',
        params: data
    })
}

//普通用户注册
export function register_putong(data) {
    return request({
    url: '/api/public/nregister',
    method: 'post',
    data
    })
}

export function settingPass(data) {
    return request({
    url: '/api/settingPass',
    method: 'post',
    data
    })
}


//获取用户信息
export function getUserInfo(data) {
    return request({
        url: '/api/public/info',
        method: 'get',
        params: data
    })
}


//修改用户信息
export function updateUserInfo(data) {
    return request({
    url: '/api/user/edit',
    method: 'post',
    data
    })
}

//获取所有用户列表
export function getUserList(data) {
    return request({
        url: '/api/user/enterpriseList',
        method: 'get',
        params: data
    })
}

//重置用户密码
export function resetPWD(data) {
    return request({
        url: '/api/energy/reset',
        method: 'get',
        params: data
    })
}

//获取用户登录记录
export function getUserlog(data) {
    return request({
        url: '/api/user/login/list',
        method: 'get',
        params: data
    })
}
//获取权限信息
export function roleList(data) {
    return request({
        url: '/api/user/role_list',
        method: 'get',
        params: data
    })
}
//新增管理员
export function addUser(data) {
    return request({
        url: '/api/user/enUserAdd',
        method: 'post',
        data
    })
}
//编辑管理员
export function editUser(data) {
    return request({
        url: '/api/user/uEdit',
        method: 'post',
        data
    })
}
//权限查看
export function roleType(data) {
    return request({
        url: '/api/user/userRoleType',
        method: 'get',
        params:data
    })
}
/***企业标准编制系统***/
// 查询用户详情
export function userInfoDetail(data) {
    return request({
        url: '/api/public/userInfo',
        method: 'get',
        params:data
    })
}